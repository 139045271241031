document.addEventListener("DOMContentLoaded", function() {
  var videoPlayer = document.getElementById("article-video-player");
  var articleIdElement = document.getElementById("article-id");
  var articleId = articleIdElement.textContent.trim(); // Get the text content and remove any leading/trailing whitespace
  videoPlayer.addEventListener("play", function() {
    // Make an AJAX request to increment the play view count.
    fetch("/articles/" + articleId + "/increment_view", {
      method: "GET",
      headers: {
        "X-CSRF-Token": "<%= form_authenticity_token %>",
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      if (response.ok) {
        // Do something on success, like updating the view count display.
      } else {
        console.error("Failed to increment view count.");
      }
    })
    .catch(error => {
      console.error(error);
    });
  });
});
