// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "../stylesheets/application";
import { initBurger } from '../components/navbar';
import "../components/trix-counter";
import "../components/articles";

document.addEventListener("turbolinks:load", function() {
  initBurger();
})

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

// <<<<<<< HEAD
// $('select').selectpicker();
// =======

// Add Choices Dropdown
const Choices = require('choices.js');
document.addEventListener("turbolinks:load", function() {
    var dropDownSelects = new Choices('#choices-author-select', { removeItemButton: true })
});
//
document.addEventListener("turbolinks:load", function() {
    var dropDownSelects = new Choices('#choices-language-select', { removeItemButton: true })
});
//
document.addEventListener("turbolinks:load", function() {
    var dropDownSelects = new Choices('#choices-domaines-select', { removeItemButton: true })
});
//
document.addEventListener("turbolinks:load", function() {
    var dropDownSelects = new Choices('#choices-genres-select', { removeItemButton: true })
});
//
document.addEventListener("turbolinks:load", function() {
    var dropDownSelects = new Choices('#choices-title-collection-select', { removeItemButton: true })
});
//
document.addEventListener("turbolinks:load", function() {
    var dropDownSelects = new Choices('#choices-serie-select', { removeItemButton: true })
});
//
document.addEventListener("turbolinks:load", function() {
    var dropDownSelects = new Choices('#choices-keyword-select', { removeItemButton: true })
});